import { DrawerProps } from "@chakra-ui/react";
import {
	createSelector,
	createSlice,
	PayloadAction,
	type Reducer,
	type Selector,
	type Slice,
} from "@reduxjs/toolkit";
import { RegisterOptions } from "react-hook-form";
import { RootState } from "../store";
import { Endpoints } from "./formManagerMiddleware";

export type FormFieldUIControlType =
	| "input"
	| "message"
	| "number-input"
	| "select"
	| "slider"
	| "switch"
	| "drop-zone";

export type SelectControlOptions = {
	extraDataOptionsField?: string;
	// TODO: fix any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optionRenderer?: (option: any) => React.ReactNode;
};

export type FormField = {
	id: string;
	type: FormFieldUIControlType;
	label: string;
	helperText?: string;
	inputOptions?: {
		domInputControlType?: React.HTMLInputTypeAttribute;
		accept?: string[];
	};
	selectOptions?: SelectControlOptions;
	showWhen?: {
		entityField: string;
		// TODO: fix any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		hasValue: any;
	};
	registerOptions: RegisterOptions;
	isReadOnly?: boolean;
	isRequired?: boolean;
	validateFormOnChange?: boolean;
};

export type Wrapper = "partFormWrapper";

export type FormContainer = "drawer" | "modal";

export type FormAttributes = {
	id: string;
	patchEndpoint: Endpoints;
	postEndpoint?: Endpoints;
	headingText: string;
	fields: FormField[];
	// TODO: fix any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editedEntity?: any;
	// TODO: fix any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	submittedEntity?: any;
	// TODO: fix any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extraData?: any;
	// TODO: fix any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	postEntityTemplate?: any;
	isPOST?: boolean;
	isReadOnly?: boolean;
	placement?: DrawerProps["placement"];
	wrapper?: Wrapper;
	container?: FormContainer;
	onSubmit?: () => void;
	allowCleanFormSubmit?: boolean;
};

export type FormManagerState = {
	isFormOpen: boolean;
	isSaving: boolean;
	isSuccess: boolean;
	isError: boolean;
	isDirty: boolean;
	formAttributes: FormAttributes | null;
	networkCallError: { error: string; message: string[] } | null;
};

const initialState: FormManagerState = {
	isFormOpen: false,
	isSaving: false,
	isError: false,
	isSuccess: false,
	isDirty: false,
	formAttributes: null,
	networkCallError: null,
};

export const formManagerSlice: Slice<FormManagerState> = createSlice({
	name: "system:forms",
	initialState,
	reducers: {
		openForm: (state, action: PayloadAction<FormAttributes>) => {
			state.isFormOpen = true;
			state.isError = false;
			state.isSuccess = false;
			const attributes = action.payload;
			if (!attributes.editedEntity && !attributes.postEndpoint) {
				throw new Error(
					`Attempting creation of new entity for the form "${attributes.id}" without post endpoint`
				);
			} else if (!attributes.editedEntity && attributes.postEndpoint) {
				attributes.isPOST = true;
				attributes.editedEntity = attributes.fields.reduce((acc, val) => {
					return { ...acc, [val.id]: null };
				}, {});
			}
			state.formAttributes = attributes;
			return state;
		},
		closeForm: (
			state,
			action: PayloadAction<{ isSucces: boolean; isError: boolean }>
		) => {
			state.formAttributes = null;
			state.isFormOpen = false;
			state.isSaving = false;
			state.isError = action.payload.isError;
			state.isSuccess = action.payload.isSucces;
			state.isDirty = false;
			state.networkCallError = null;
			return state;
		},
		submitForm: (state) => {
			state.isSaving = true;
			return state;
		},
		setFormError: (
			state,
			action: PayloadAction<{ error: string; message: string[] }>
		) => {
			state.isSaving = false;
			state.isSuccess = false;
			state.isError = true;
			if (action.payload) {
				state.networkCallError = action.payload;
			}
			return state;
		},
		setFormDirty: (state) => {
			state.isDirty = true;
			return state;
		},
		// TODO: fix any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		updateEditedFormEntity: (state, action: PayloadAction<any>) => {
			state.formAttributes!.editedEntity = action.payload;
			return state;
		},
	},
});

export const {
	openForm,
	closeForm,
	submitForm,
	setFormError,
	setFormDirty,
	updateEditedFormEntity,
} = formManagerSlice.actions;

export default formManagerSlice.reducer as Reducer<FormManagerState>;

// selectors
const selectSelf: Selector<RootState, RootState> = (state: RootState) => state;

export const formStateSelector: Selector<RootState, FormManagerState> =
	createSelector(selectSelf, (state) => state[formManagerSlice.name]);

export const isFormOpenSelector: Selector<RootState, boolean> = createSelector(
	formStateSelector,
	(state) => state.isFormOpen
);

export const isFormSavingSelector: Selector<RootState, boolean> =
	createSelector(formStateSelector, (state) => state.isSaving);

export const formAttributesSelector: Selector<
	RootState,
	FormAttributes | null
> = createSelector(formStateSelector, (state) => state.formAttributes);
