// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"stage-83b69f2b"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_CLOUD_ENV,
	release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
	autoSessionTracking: true,

	// Add optional integrations for additional features
	integrations: [
		Sentry.replayIntegration({
			maskAllInputs: false,
			maskAllText: false,
			blockAllMedia: false,
		}),
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
	],

	tracePropagationTargets: [
		"localhost",
		/^https:\/\/(dev|stage|app)\.rototip\.com/,
	],

	beforeSend(event) {
		// Check if it is an exception, and if so, show the report dialog
		if (event.exception) {
			Sentry.showReportDialog({ eventId: event.event_id });
		}
		return event;
	},

	tracesSampleRate: 1,
	profilesSampleRate: 1.0,

	// Define how likely Replay events are sampled.
	replaysSessionSampleRate: 0.1,
	// Define how likely Replay events are sampled when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	debug: false,
	enabled:
		process.env.NODE_ENV !== "development" && process.env.CLOUD_ENV !== "local",
	enableTracing:
		process.env.NODE_ENV !== "development" && process.env.CLOUD_ENV !== "local",
});
